<template>
  <a-spin :spinning="loading">
    <div class="example-table">
      <p>Upload a CSV file with the following columns:</p>
      <div class="content">
        <div class="content-left">
          <div class="table">
            <artmisads-table
              :columns="columns"
              row-key="id"
              :data-source="exampleDataList"
              :pagination="false"
            >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name === 'status' && (record.status === 'inactive' || record.status === 'active')">
                <showStatus :isActive="record.status === 'active'" :noBorder="true"/>
              </template>
            </template>
            </artmisads-table>
          </div>
          <div class="file-name" v-if="fileName">
            <div>
              <span>{{fileName}}</span>
              <DeleteOutlined @click="clearFile"/>
            </div>
          </div>
          <a-upload
            name="avatar"
            list-type="picture-card"
            :show-upload-list="false"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            :before-upload="beforeUpload"
            @change="handleChange"
            v-else
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <svg class="icon shangchuan2" aria-hidden="true">
              <use xlink:href="#icon-shangchuan2-xi"></use>
            </svg>
            Choose a file...
          </a-upload>
        </div>
        <div class="content-right">
          <div class="description">
            <span class="line"></span>
            <span class="text">Description</span>
          </div>
          <div class="required">
            <span class="line"></span>
            <span class="text">Required</span>
          </div>
          <div class="example">
            <span class="line"></span>
            <span class="text">Example</span>
          </div>
        </div>
      </div>
      <div class="next-btn">
        <artmisads-button type="primary" @click="toNext" v-if="dataList.failList.length || dataList.successList.length">
          Next
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-you-cu"></use>
          </svg>
        </artmisads-button>
        <artmisads-button type="primary" v-else disabled>Next</artmisads-button>
      </div>
    </div>
  </a-spin>
</template>
<script setup>
  import { DeleteOutlined } from "@ant-design/icons-vue";
  import { message } from 'ant-design-vue';
  import { ref } from 'vue';

  import showStatus from '../../Brands/components/showStatus.vue';
  import { productsApi } from '../../../../server/index'

  const fileName = ref('');
  const loading = ref(false);
  const props = defineProps([
    'setDataList', 'setShowExample', 'dataList'
  ]);

  const clearFile = () => {
    fileName.value = '';
    props.setDataList({ successList: [], failList: [] });
  }

  const exampleDataList = [
    {
      id: 1,
      asin: 'Ensure the ASINs are associated with your active brands on the Artemis Ads platform.',
      commission: ' Specify the commission rate that affiliates will see. Format it as a percentage (e.g., 20%).',
      status: 'Indicate the status of each product to determine if affiliates can view and promote it. Use one of the following statuses: Active or Inactive.'
    },
    {
      id: 2,
      asin: 'Yes',
      commission: 'No',
      status: 'No',
    },
    {
      id: 3,
      asin: 'B001HSIE123134',
      commission: '20%',
      status: '',
    },
    {
      id: 4,
      asin: 'B001HSIE123134',
      commission: '',
      status: 'active',
    },
    {
      id: 5,
      asin: 'B001HSIE123134',
      commission: '15%',
      status: '',
    },
    {
      id: 6,
      asin: 'B001HSIE123134',
      commission: '',
      status: 'inactive',
    },
  ];
  const columns = [
    {
      title: 'ASINs',
      dataIndex: 'asin',
    },
    {
      title: 'Public Commission',
      dataIndex: 'commission',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status'
    }
  ];

  const handleChange = async ({file}) => {
    const { type, originFileObj, percent, name } = file;
    let form = new FormData();
		form.append('file', originFileObj);
    if (percent === 100) {
      if (type !== 'text/csv') {
        return message.error('Please upload a csv file.');
      } else {
        loading.value = true;
        let res = await productsApi.preCommissionCsv(form);
        const { success, error, data } = res;
        if (success) {
          const successList = data.successList || [];
          const failList = data.failList || [];
          if (!successList.length && !failList.length) {
            message.warning('No matching data found.');
          } else {
            message.success('Success');
            const dataList = { successList, failList };
            props.setDataList(dataList);
            fileName.value = name;
          }
          
        }
        loading.value = false;
      }
    }
  }

  const toNext = () => {
    props.setShowExample(false);
  }

</script>

<style lang="less" scoped>
  .example-table {
    > p {
      letter-spacing: 0.4px;
      line-height: 20px;
      color: var(--dark-2);
      padding-top: 8px;
      font-weight: 500;
    }
    .content {
      padding: 12px 0 24px ;
      display: flex;
      :deep(.ant-table-tbody) {
        > tr {
          &:first-child {
            background-color: var(--table-cell-bg-3);
            > td {
              background-color: var(--table-cell-bg-3);
              color: var( --dark-3);
              letter-spacing: 0.3px;
              font-size: var(--font-size-small);
            }
            &:hover {
              background-color: var(--table-cell-bg-3);
              > td {
               background-color: var(--table-cell-bg-3);
              }
            }
          }
          &:nth-child(2) {
            background-color: var(--table-cell-bg-2);
            > td {
              background-color: var(--table-cell-bg-2);
              color: var( --dark-1);
              font-size: var(--font-size-mormal);
            }
            &:hover {
              background-color: var(--table-cell-bg-2);
              > td {
                background-color: var(--table-cell-bg-2);
              }
            }
          }
          &:nth-child(n+3) {
            background-color: var(--table-cell-bg-1);
            > td {
              background-color: var(--table-cell-bg-1);
              color: var( --dark-1);
              &:first-child {
                font-weight: 500;
              }
            }
            &:hover {
              background-color: var(--table-cell-bg-1);
              > td {
                background-color: var(--table-cell-bg-1);
              }
            }
          }
        }
      }
    }
    .content-right {
      margin-left: 8px;
      margin-top: 42px;
      .description,
      .required,
      .example {
        display: flex;
        .line {
          width: 2px;
          height: 100%;
        }
        .text {
          font-weight: 500;
          line-height: 20px;
          padding-left: 8px;
          padding-right: 8px;
          height: 20px;
          align-self: center;
          border-radius: 4px;
          margin-left: 25px;
        }
      }
      .description {
        height: 92px;
        .line {
          background-color: var(--primary-bg);
        }
        .text {
          color: var(--primary-text);
          background-color: var(--table-cell-bg-3);
          border: 1px solid var(--primary-border-3);
        }
      }
      .required {
        height: 50px;
        margin-top: 4px;
        .line {
          background-color: var(--yellow-5);
        }
        .text {
          color: var(--yellow-4);
          background-color: var(--table-cell-bg-2);
          border: 1px solid var(--yellow-5);
        }
      }
      .example {
        height: 215px;
        margin-top: 4px;
        .line {
          background-color: var(--green-4);
        }
        .text {
          color: var(--green-1);
          background-color: var(--table-cell-bg-1);
          border: 1px solid var(--green-2);
        }
      }
    }
    .table {
      width: 940px;
    }
    .content-left {
      .file-name {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 64px;
        cursor: pointer;
        background: var(--big-bg-1);
        border-radius: 8px;
        color: var(--dark-2);
        > div {
          display: flex;
          height: 28px;
          padding: 0 8px;
          border-radius: 8px;
          align-self: center;
          &:hover {
            background-color: var(--bg-gray-4);
          }
          > span {
            color: var(--dark-1);
            align-self: center;
            &:first-child {
              margin-right: 12px;
            }
            :deep(svg) {
              width: 16px;
              height: 16px;
              fill: var(--dark-3);
              &:hover {
                fill: var(--dark-1);
              }
            }
          }
        }
        
      }
      :deep(.ant-upload-wrapper){
        margin-top: 8px;
        .ant-upload.ant-upload-select {
          width: 100%;
          height: 64px;
          color: var(--dark-2);
          background: var(--big-bg-1);
          border: 2px dashed var(--border-1);
          border-radius: 8px;
          font-size: var(--font-size-h-l);
          &:hover {
            background: var( --bg-gray-2);
            border: 2px dashed var(--bg-gray-7);
          }
          svg {
            width: 32px;
            height: 32px;
            fill: var(--dark-2);
            margin-right: 16px;
          }
        }
      }
    }
    .next-btn {
      border-top: 1px solid var(--border-4);
      padding: 10px 24px 12px 0;
      display: flex;
      justify-content: right;
      > button {
        border: 2px solid var(--primary);
        box-shadow: var(--section-box-shadow-2);
        background-color: var(--color-white) !important;
        color: var(--primary);
        svg {
          fill: var(--primary);
          margin-left: 8px;
        }
      }
    }
  }
</style>