<template>
  <div class="file-bar" ref="fileBarRef">
    <div class="content-wrap">
      <h2></h2>
      <div class="content">
        <a-dropdown :getPopupContainer="() => fileBarRef">
          <template #overlay>
            <artmisads-dropdown-menu @click="handleMenuClick">
              <a-menu-item key="all">Export All Products</a-menu-item>
              <a-menu-item key="active">Export All Active Products</a-menu-item>
              <a-menu-item key="inactive">Export All Inactive Products</a-menu-item>
              <a-menu-item key="filter">Export with Filters</a-menu-item>
            </artmisads-dropdown-menu>
          </template>
          <artmisads-button-special>
            <svg class="icon daochu" aria-hidden="true">
              <use xlink:href="#icon-daochu"></use>
            </svg>
            <span class="btn-text">Export Products</span>
            <svg class="icon jiantou" aria-hidden="true">
              <use xlink:href="#icon-jiantou-xia"></use>
            </svg>
          </artmisads-button-special>
        </a-dropdown>
        <artmisads-button-special @click="() => setShowUpdateProductModal(true)">
          <svg class="icon shangchuan2" aria-hidden="true">
            <use xlink:href="#icon-shangchuan2"></use>
          </svg>
          <span class="btn-text">Bulk Update Products Via CSV</span>
        </artmisads-button-special>
      </div>
    </div>
    <div class="activate-brand" ref="activateBrand">
    <artmisads-modal
      v-model:open="showUpdateProductModal"
      title="Update Products via CV Upload"
      :width="1200"
      :getContainer="() => fileBarRef"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
      :onCancel="onCancel"
    >
      <div>
        <exampleTable
          v-if="showExample"
          :setDataList="setDataList"
          :dataList="dataList"
          :setShowExample="setShowExample"
        />
        <failSuccessList
          v-else
          :dataList="dataList"
          :showExample="showExample"
          :setDataList="setDataList"
          :setShowUpdateProductModal="setShowUpdateProductModal"
          :getProductList="getProductList"
          :setShowExample="setShowExample"
          :onCancel="onCancel"
        />
      </div>
    </artmisads-modal>
  </div>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import exampleTable from './exampleTable.vue';
  import failSuccessList from './failSuccessList.vue';
  import { productsApi } from '../../../../server/index'

  const showUpdateProductModal = ref(false);
  const showExample = ref(true);
  const dataList = ref({
    failList: [],
    successList: []
  });
  const fileBarRef = ref(null);
  const props = defineProps([ 'params', 'getProductList']);

  const setShowUpdateProductModal = status => {
    showUpdateProductModal.value = status;
  }

  const setShowExample = status => {
    showExample.value = status;
  }
  const setDataList = data => {
    dataList.value = data;
  }

  const onCancel = () => {
    showUpdateProductModal.value = false;
    dataList.value = {
      failList: [],
      successList: []
    };
    showExample.value = true;
  }

  const handleMenuClick = async ({ key }) => {
    let exportParams = { exportType: key }, newExportParams = {};
    if (key === 'filter') {
      exportParams = {...props.params, ...exportParams };
    }
    for(let item in exportParams) {
      if (exportParams[item]) {
        newExportParams[item] = exportParams[item];
      }
    }
    console.log(123333,newExportParams);
    
    const finalParams = {
      qs: newExportParams,
      fileName: 'Products.csv'
    }
    productsApi.exportProduct(finalParams);
  }


</script>
<style lang="less" scoped>
  .file-bar {
    display: flex;
    justify-content: right;
    padding: 12px 16px 0 16px;
    background-color: var(--color-white);
    .content-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--border-4);
      > h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .content {
      display: flex;
      > button {
        cursor: pointer;
        display: flex;
        margin-left: 16px;
        .shangchuan2 {
          width: 20px;
          height: 20px;
          fill: var(--dark-1);
          margin-right: 8px;
        }
        .jiantou {
          width: 16px;
          height: 16px;
          fill: var(--dark-3);
          margin-left: 8px;
        }
        .daochu {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          fill: var(--dark-1);
        }
        > svg {
          align-self: center;
        }
        > span {
          align-self: center;
        }
        .btn-text {
          align-self: center;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    
  }
</style>
