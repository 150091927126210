<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :row-selection="{ selectedRowKeys: props.selectedRowKeys, onChange: props.onSelectChange }"
      :columns="columns"
      row-key="id"
      :data-source="props.dataSource"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :loading="props.loading"
      @change="onChangePage"
      rowClassName="table-item"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.name === 'action'">
          <div class="set-commission">
            <artmisads-select-button size="small" @click="() => onSetCommission(record.id)">Set Commission</artmisads-select-button>
            <artmisads-button :isSquare="true" type="primary" v-if="record.status === 'inactive'" size="small" @click="() => changeActive(record)">Activate</artmisads-button>
            <artmisads-select-button v-else size="small" @click="() => setShowDeactivateModal(true, record)">Deactivate</artmisads-select-button>
          </div>
        </template>
        <template v-if="column.name === 'commission'">
          <span :class="record.status === 'active' ? 'active-commission' : 'inactive-commission'">{{`${Number(record.commission).toFixed(1)}%`}}</span>
        </template>
        <template v-if="column.name === 'status'">
          <showStatus :isActive="record.status === 'active'" :noBorder="true"/>
        </template>
      </template>
    </artmisads-table>
    <artmisads-modal
      v-model:open="showDeactivateModal"
      :title="`Are you sure you want to Deactivate this Product?`"
      :width="500"
      :onCancel="onCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="inactive-content">
        <p>Deactivating a Product will archive all active Publisher/Creator links. and make this product unavailable to publishers and creators.</p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
        <artmisads-button @click="confirmInactive" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
  </div>
</template>
<script lang="jsx" setup>
  import { ref } from 'vue';
  import showStatus from '../Brands/components/showStatus.vue';

  const deactivateRecord = ref('');
  const showDeactivateModal = ref(false);
  const tableRef = ref(null);
  const props = defineProps([
    'listTotal', 'setParams', 'setShowSetCommission', 'setSelectedRowKeys', 'setSelectSingleProduct',
    'params', 'dataSource', 'loading', 'selectedRowKeys', 'onSelectChange', 'getProductList', 'onChangeActiveItems'
  ]);


  const confirmInactive = async () => {
    await changeActive(deactivateRecord.value);
    showDeactivateModal.value = false;
  }

  const setShowDeactivateModal = (status, record) => {
    showDeactivateModal.value = status;
    if (status) {
      deactivateRecord.value = record;
    } else {
      deactivateRecord.value = '';
    }
  }

  const columns = ref([
    {
      title: 'Product',
      dataIndex: 'title',
      width: 'auto',
      customRender: ({ text, record }) => {
        return (
          <a href={`/#/admin/product/${record.id}`} class="product-item">
            <img src={record.imageUrl}/>
            <div class="text">
              <p class="title">{text}</p>
              <span class="asin">{record.asin}</span>
            </div>
          </a>
        )
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      width: 120,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '10%',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      customRender: ({ text, record }) => `$${text}`,
      width: 100,
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      name: 'commission',
      width: 130,
    },
    {
      title: 'Status',
      dataIndex: 'id',
      name: 'status',
      width: 110,
    },
    {
      name: 'availiability',
      title: 'Availiability',
      dataIndex: 'availiability',
      width: 120,
      customRender: ({ text, record }) => `${text == 'IN_STOCK' ? 'In Stock' : 'Out Of Stock'}`,
      
    },
    
    {
      name: 'action',
      title: 'Action',
      dataIndex: 'id',
      width: 160,
    },
  ]);

  const onSetCommission = id => {
    props.setShowSetCommission(true);
    props.setSelectSingleProduct(id);
  }

  const onChangePage = data => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    props.setParams(newParams);
    props.getProductList(true);
  }
  const changeActive = async record => {
    let { status, id } = record;
    let params = [id];
    status = status === 'active' ? 'inactive' : 'active';
    props.onChangeActiveItems(status, params);
  }
</script>
<style lang="less" scoped>
  :deep(.ant-table) {
    .set-commission {
      > button {
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
    table {
      table-layout: fixed !important;
    }
    .inactive-commission {
      color: var(--dark-4);
    }
    .status-text {
      text-transform: capitalize;
    }
    .product-item {
      display: flex;
      img {
        width: 56px;
        height: 56px;
        margin-right: 12px;
        // border: 1px solid var( --border-1);
        border-radius: 8px;
        object-fit: contain;
      }
      .text {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        .title {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--dark-1);
          font-weight: 500;
          font-size: var(-font-size-middle);
          text-decoration: underline;
        }
        .asin {
          color: var(--dark-3);
          font-weight: 500;
          font-size: var(-font-size-min);
          letter-spacing: 0.3px;
          text-align: right;
          line-height: 16px;
          text-align: left;
        }
      }
    }
  }
</style>