<template>
  <div class="filter" ref="filterRef">
    <a-popover v-model:open="visible" :getPopupContainer="() => filterRef" placement="bottomLeft" destroyTooltipOnHide="true" trigger="click">
      <template #content>
        <div class="content"  ref="filterContentRef">
          <div v-if="brandswithAvailableProducts.length > 1" class="select-item">
            <span>Brand</span>
            <artmisads-select
              v-model:value="defaultFilter.brandId"
              :getPopupContainer="() => filterContentRef"
              :options="brandswithAvailableProducts"
            />
          </div>
          <div class="select-item">
            <span>Status</span>
            <artmisads-select
              v-model:value="defaultFilter.status"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="active">Active</a-select-option>
              <a-select-option value="inactive">Inactive</a-select-option>
            </artmisads-select>
          </div>
          <div class="commission-min">
            <span class="commission-min-text">Commission Min</span>
            <a-input-number
              v-model:value="defaultFilter.commission"
              :min="1"
              :max="100"
              :formatter="value => (value ? `${value}%` : value)"
              :parser="value => value.replace('%', '')"
              ref="commissionMinRef"
            />
            <span v-if="showDefalutText" class="defalut-text" @click="clickDefalutText">Please enter...</span>
          </div>
          <div class="select-item">
            <span>Availability</span>
            <artmisads-select
              v-model:value="defaultFilter.availability"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK">In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK">Out of Stock</a-select-option>
            </artmisads-select>
          </div>
          <div class="select-item">
            <span>Price</span>
            <artmisads-select
              v-model:value="defaultFilter.price"
              :getPopupContainer="() => filterContentRef"
            >
              <a-select-option :value="0">All</a-select-option>
              <a-select-option :value="1">Under $10</a-select-option>
              <a-select-option :value="2">$10 To $25</a-select-option>
              <a-select-option :value="3">$25 To $50</a-select-option>
              <a-select-option :value="4">$50 To $100</a-select-option>
              <a-select-option :value="5">$100 To $200</a-select-option>
              <a-select-option :value="6">$200 & Above</a-select-option>
            </artmisads-select>
          </div>
          <div class="save-btn">
            <artmisads-button type="primary" size="small" @click="onSave">Save</artmisads-button>
          </div>
          <div @click="onReset" class="reset-btn">
            <artmisads-select-button size="small">Reset {{filterNum || ''}} Filters</artmisads-select-button>
          </div>
        </div>
      </template>
      <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filterNum"
        showDownIcon="true"
      >Filters</artmisads-select-button>
    </a-popover>
  </div>
</template>
<script setup>
  import { onBeforeMount, watch, ref, computed, onMounted } from 'vue';

  const visible = ref(false);
  const showDefalutText = ref(true);
  const commissionMinRef = ref(null);
  const filterRef = ref(null);
  const filterContentRef = ref(null);
  const props = defineProps([ 'setParams', 'params', 'getProductList', 'setSelectedRowKeys', 'brandswithAvailableProducts' ]);
  const priceArr = [
    { maxPrice: '', minPrice: ''},
    { maxPrice: 10, minPrice: ''},
    { maxPrice: 25, minPrice: 10},
    { maxPrice: 50, minPrice: 25},
    { maxPrice: 100, minPrice: 50},
    { maxPrice: 200, minPrice: 100},
    { maxPrice: '', minPrice: 200},
  ]

  const filterNum = computed(() => {
    let num = 0;
    if (defaultFilter.value.status !== 'all') {
      num += 1;
    }
    if (defaultFilter.value.availability !== 'all') {
      num += 1;
    }
    if (defaultFilter.value.commission) {
      num += 1;
    }
    if (defaultFilter.value.price) {
      num += 1;
    }
    if (defaultFilter.value.brandId) {
      num += 1;
    }
    return num;
  })

  const defaultFilter = ref({
    status: 'all',
    availability: 'all',
    commission: '',
    price: 0,
    brandId: ''
  })

  const clickDefalutText = () => {
    showDefalutText.value = false;
    commissionMinRef.value.focus();
  }

  const onReset = () => {
    defaultFilter.value = {
      status: 'all',
      availability: 'all',
      commission: '',
      price: 0,
      brandId: ''
    };
  }

  const onSave = () => {
    let newParams = { ...props.params };
    let priceItem = priceArr[defaultFilter.value.price];
    newParams.maxPrice = priceItem.maxPrice;
    newParams.minPrice = priceItem.minPrice;
    newParams.status = defaultFilter.value.status === 'all' ? '' : defaultFilter.value.status;
    newParams.availability = defaultFilter.value.availability === 'all' ? '' : defaultFilter.value.availability;
    newParams.commission =  defaultFilter.value.commission;
    newParams.brandId =  defaultFilter.value.brandId;
    newParams.page =  1;
    props.setParams(newParams);
    props.setSelectedRowKeys([]);
    props.getProductList();
    visible.value = false;
  }

  watch(() => defaultFilter.value.commission, (newValue) => {
    showDefalutText.value = !newValue;
  })

  onMounted(() => {
    const timer = setTimeout(() => {
      const priceArrIndex = priceArr.findIndex(item => (
        item.maxPrice === props.params.maxPrice && item.minPrice === props.params.minPrice
      ))
      defaultFilter.value.price = priceArrIndex;
      defaultFilter.value.status = props.params.status || 'all';
      defaultFilter.value.commission = props.params.commission;
      defaultFilter.value.brandId = props.params.brandId;
      defaultFilter.value.availability = props.params.availability || 'all';
      clearTimeout(timer);
    }, 1000)
  })


</script>
<style lang="less" scoped>
  .filter {
    .content {
      padding-top: 16px;
      .save-btn {
        display: flex;
        margin: 10px 16px 10px 0;
        justify-content: right;
      }
      .reset-btn {
        display: flex;
        justify-content: center;
        border-top: 1px solid var(--border-4);
        padding-top: 11px;
        padding-bottom: 11px;
      }
      .commission-min, .select-item {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .select-item {
        > span {
          margin-bottom: 4px;
          font-weight: 500;
          font-size: var(--font-size-small);
          color: var(--dark-2);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
      }
      .commission-min {
        position: relative;
        .commission-min-text {
          margin-bottom: 4px;
          color: var(--dark-2);
          font-weight: 500;
          font-size: var(--font-size-small);
          line-height: 18px;
        }
        .defalut-text {
          position: absolute;
          font-family: Sora-Regular;
          line-height: 20px;
          left: 28px;
          top: 28px;
          color: var(--dark-4);
        }
      }
      :deep(.ant-select) {
        width: 200px;
      }
      :deep(.ant-input-number) {
        width: 200px !important;
      }
    }
  }
  
</style>
