<template>
  <div class="product">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangpin"></use>
        </svg>
      </breadcrumbs>
    </div>
    <div class="content-wrap">
      <div class="content-main">
        <fileBar
          :params="params"
          :getProductList="getProductList"
        />
        <div class="content">
          <operationBar
            :onChangeActiveItems="onChangeActiveItems"
            :selectedRowKeys="selectedRowKeys"
            :setSelectedRowKeys="setSelectedRowKeys"
            :productList="productList"
            :getProductList="getProductList"
            :params="params"
            :setParams="setParams"
            :listTotal="listTotal"
            :setShowSetCommission="setShowSetCommission"
            :brandswithAvailableProducts="brandswithAvailableProducts"
          />
          <tableContent
            :setParams="setParams"
            :loading="loading"
            :params="params"
            :dataSource="productList"
            :selectedRowKeys="selectedRowKeys"
            :getProductList="getProductList"
            :onSelectChange="onSelectChange"
            :total="total"
            :onChangeActiveItems="onChangeActiveItems"
            :listTotal="listTotal"
            :setShowSetCommission="setShowSetCommission"
            :setSelectedRowKeys="setSelectedRowKeys"
            :setSelectSingleProduct="setSelectSingleProduct"
          />
        </div>
      </div>
    </div>
    <setPublicCommission
      :showSetCommission="showSetCommission"
      :productList="productList"
      :selectedRowKeys="selectedRowKeys"
      :setShowSetCommission="setShowSetCommission"
      :getProductList="getProductList"
      :selectSingleProduct="selectSingleProduct"
      :setSelectSingleProduct="setSelectSingleProduct"
    />
    <contextHolder />
  </div>
</template>
<script lang="jsx" setup>
  import { onBeforeMount, onMounted, ref, watch } from 'vue';
  import setPublicCommission from './setPublicCommission.vue';

  import { useSellerProductStore } from '../../../storeData/sellerproduct';
  import { productsApi } from '../../../server/index'
  import breadcrumbs from '../breadcrumbs.vue';
  import tableContent from './tableContent.vue';
  import operationBar from './operationBar.vue';
  import fileBar from './fileBar/index.vue';
  import { message, notification } from 'ant-design-vue';

  const breadcrumbsData = {
    title: 'Your Products',
    des: 'Review you active ASINs that will be listed on our marketplace for Creator discovery',
    color:'#4682FA',
    hasTitleIcon:true,
    links: [
      {
        name: 'Home',
        path: '/#/admin/welcome'
      },
      {
        name: 'Products',
        path: '/#/admin/product'
      }
    ]
  };
  const store = useSellerProductStore();
  const brandswithAvailableProducts = ref([]);
  const selectSingleProduct = ref('');
  const showSetCommission = ref(false);
  const selectedRowKeys = ref([]);
  const loading = ref(true);
  const productList = ref([]);
  const listTotal = ref(0);
  const params = ref({
    availability: '',
    commission: '',
    maxPrice: '',
    minPrice: '',
    page: 1,
    size: 20,
    sortType: 1,
    status: '',
    title: '',
    brandId: ''
  })

  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Notice`,
      description: description,
      placement: 'topRight',
      duration: 3
    });
  };

  const setSelectSingleProduct = id => {
    selectSingleProduct.value = id;
  }

  const setShowSetCommission = status => {
    showSetCommission.value = status;
  }


  watch(() => params.value.sortType, () => {
    getProductList();
  })

  const setParams = data => {
    params.value = data;
  }

  const setSelectedRowKeys = data => {
    selectedRowKeys.value = data;
  }

  const onChangeActiveItems = async (status, ids) => {
    loading.value = true;
    let res, params = ids;
    if (status === 'active') {
      res = await productsApi.activeProduct(params);
    } else {
      res = await productsApi.inactiveProduct(params);
    }
    const { success, error, code } = res;
    if (success) {
      getProductList();
      message.success('Success');
    } else {
      if (code === '1014') {
        openNotification(error);
      }
    }
    loading.value = false;
  }

  const onSelectChange = keys => {
    selectedRowKeys.value = keys;
  };

  const getProductList = async (goToTop = false) => {
    loading.value = true;
    let newparams={...params.value};
    const newParams = newparams = Object.keys(newparams).reduce((acc, key) => {
      if(key == 'status' && newparams[key]=='all'){

      }else if(newparams[key] !== null && newparams[key] !== undefined && newparams[key] !== '' ) {
        acc[key] = newparams[key];
      }
      return acc;
    }, {});  
    const res = await productsApi.getProductList(newParams);
    const { success, data } = res;
    if (success) {
      if (params.value.page > 1 && !data.data.length) {
        setParams({...params.value, page: 1});
        getProductList();
      } else {
        productList.value = data.data;
        listTotal.value = data.total;
        if (goToTop) {
          document.querySelector('.product').scrollIntoView({
            behavior: 'smooth'
          })
        }
       
      }
      
    }
    loading.value = false;
  }

  const getBrandswithAvailableProducts = async () => {
    const res = await productsApi.getBrandswithAvailableProducts();
    const { success, data } = res;
    if (success && data.length) {
      const list = data.map(item => {
        item.value = item.id;
        item.label = item.amazonBrandName;
        return item;
      })
      brandswithAvailableProducts.value = list;
    }
  }

  const checkFromBrandList = () => {
    let paramsFromBrand = store.params;
    if (paramsFromBrand) {
      params.value.brandId = paramsFromBrand.brandId;
      params.value.status = paramsFromBrand.status;
      store.setParams({});
    }
  }

  onBeforeMount(() => {
    getBrandswithAvailableProducts();
  })

  onMounted(() => {
    checkFromBrandList();
    getProductList();
  })


</script>
<style lang="less" scoped>
  .product {
    padding:0 16px 16px;
  }
  .content-main {
    border-radius: 12px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
  }
  .content {
    padding: 15px;
  }
</style>