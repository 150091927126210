<template>
  <div class="active" v-show="isActive" :style="noBorder?style:''">
      <div class="circle"><div></div></div>
      <div>Active</div>
  </div>
  <div class="inactive" v-show="!isActive" :style="noBorder?style:''" >
      <div class="circle"><div></div></div>
      <div>Inactive</div>
  </div>
</template>
<script setup>
  defineProps(['isActive','noBorder'])
  const style={
    border:'unset',
    background:'transparent',
    'box-shadow':'unset'
  }
</script>
<style lang="less" scoped>
  @flex:{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @font:{ 
    // opacity: 0.7;
    font-family: Sora-Medium;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 20px;
    border-radius: 4px
  };
  
.inactive{
  @font();
  display: flex;
  align-items: center;
  padding:0 3px;
  color: #878394;
  background: #EBE9F0;
  border: 1px solid var(--border-1);
  box-shadow: 0 1px 0 0 var(--box-shadow-1);
  .circle{
    @flex();
    margin-right: 2px;
    width: 12px;
    height: 12px;
    background: #DFDCE5;
    border-radius: 50%; 
    >div{
      width: 6px;
      height: 6px;
      background-color: #ABA8B4;
      border-radius: 50%;
      
    }
  }
}
.active{
  @font();
  display: flex;
  align-items: center;
  padding:0 3px;
  color: #009947;
  background: #2aae671a;
  border: 1px solid #2aae6766;
  box-shadow: 0 1px 0 0 #1a16250d;
  .circle{
    @flex();
    margin-right: 2px;
    width: 12px;
    height: 12px;
    background: #C2E7D4;
    border-radius: 50%; 
    >div{
      width: 6px;
      height: 6px;
      background-color:#2AAE67;
      border-radius: 50%;
      
    }
  }
}
</style>