<template>
  <div class="operation-bar" ref="operationRef">
    <artmisads-modal
      v-model:open="showDeactivateModal"
      :title="`Are you sure you want to Deactivate this Product?`"
      :width="500"
      :onCancel="onCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="inactive-content">
        <p>Deactivating a Product will archive all active Publisher/Creator links. and make this product unavailable to publishers and creators.</p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
        <artmisads-button @click="confirmInactive" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
    <div class="actions">
      <a-dropdown :getPopupContainer="() => operationRef" trigger="click">
        <template #overlay>
          <artmisads-dropdown-menu @click="handleMenuClick" >
            <a-menu-item key="activateProducts">
              <div class="actions-item">
                <span>Activate Products</span>
                <span v-if="productListStatus.inActiveItems.length" class="num">{{productListStatus.inActiveItems.length || ''}}</span>
              </div>
            </a-menu-item>
            <a-menu-item key="setPublicCommission">
              <div class="actions-item">
                <span>Set Public Commission</span>
                <span v-if="selectedRowKeys.length" class="num">{{selectedRowKeys.length}}</span>
              </div>
            </a-menu-item>
            <a-menu-item key="deactivateProducts">
              <div class="actions-item">
                <span>Deactivate Products</span>
                <span v-if="productListStatus.activeItems.length" class="num">{{productListStatus.activeItems.length || ''}}</span>
              </div>
            </a-menu-item>
            <a-menu-item key="clearSelection">Clear Selection</a-menu-item>
          </artmisads-dropdown-menu>
        </template>
        <artmisads-select-button showDownIcon="true">Actions</artmisads-select-button>
      </a-dropdown>
      <div class="button-groups">
        <buttonGroups
          :pagination="{
            total: props.listTotal,
            current: props.params.page,
            size: props.params.size,
          }"
          :tableData="productList"
          @pageChange="onPageChange"
        />
      </div>
    </div>
    <div class="content-right">
      <div class="search">
        <a-input-search
          v-model:value="params.title"
          placeholder="search..."
          @search="getProductList"
          allowClear="true"
        />
      </div>
      <searchFilter
        :params="params"
        :pagination="{
          total: props.listTotal,
          current: props.params.page,
          pageSize: props.params.size,
        }"
        :setParams="setParams"
        :getProductList="getProductList"
        :setSelectedRowKeys="setSelectedRowKeys"
        :brandswithAvailableProducts="brandswithAvailableProducts"
      />
      <div class="sellers">
        <artmisads-select
          v-model:value="params.sortType"
          style="width: 200px"
        >
          <a-select-option :value="1">Best Sellers</a-select-option>
          <a-select-option :value="2">Newly Added</a-select-option>
          <a-select-option :value="3">Commission</a-select-option>
          <a-select-option :value="4">Price</a-select-option>
        </artmisads-select>
      </div>
    </div>
  </div>
</template>
<script setup>
  import ButtonGroups from '../Brands/components/buttonGroups.vue';
  import { computed, ref } from 'vue';
  import searchFilter from './searchFilter.vue';
  import { message } from 'ant-design-vue';

  const showDeactivateModal = ref(false);
  const operationRef = ref(null);
  const props = defineProps([
    'setParams', 'params', 'setShowSetCommission', 'listTotal', 'brandswithAvailableProducts',
    'selectedRowKeys', 'onChangeActiveItems', 'setSelectedRowKeys', 'productList', 'getProductList'
  ]);

  const confirmInactive = async () => {
    props.onChangeActiveItems('inActive', props.selectedRowKeys);
    showDeactivateModal.value = false;
  }

  const setShowDeactivateModal = status => {
    showDeactivateModal.value = status;
  }

  const productListStatus = computed(() => {
    let activeItems = [], inActiveItems = [];
    props.selectedRowKeys.forEach(element => {
      const item = props.productList.find(i => i.id === element);
      if (item) {
          if (item.status === 'active') {
          activeItems = [...activeItems, item];
        } else {
          inActiveItems = [...inActiveItems, item];
        }
      }
      
    });
    return {
      activeItems,
      inActiveItems
    }
  })

  const onPageChange = (current) => {
    let newParams = { ...props.params };
    newParams.page =  current;
    props.setParams(newParams);
    props.getProductList();
  }

  const handleMenuClick = async ({ key }) => {
    if (!props.selectedRowKeys.length && key !== 'clearSelection') {
      return message.warning('Please select the product items.');
    };
    if (key === 'activateProducts') {
      props.onChangeActiveItems('active', props.selectedRowKeys);
    } else if (key === 'deactivateProducts') {
      setShowDeactivateModal(true);
    } else if (key === 'clearSelection') {
      props.setSelectedRowKeys([]);
    } else if (key === 'setPublicCommission') {
      props.setShowSetCommission(true);
    }
  }

</script>
<style lang="less" scoped>
  .operation-bar {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .actions {
      display: flex;
      > button {
        margin-right: 16px;
      }
    }
    .content-right {
      display: flex;
      justify-content: right;
      height: 32px;
      flex: 1;
      :deep(.ant-input-search) input {
        &::placeholder {
          text-transform: capitalize;
        }
      }
      > div {
        margin-left: 20px;
      }
    }
  }
</style>
