<template>
  <div class="set-commission" ref="setCommissionRef">
    <artmisads-modal
      v-model:open="props.showSetCommission"
      :title="`Set Public Commission for ${
        selectedRowKeys.length || 1
      } Products`"
      :width="600"
      okText="Confirm"
      :onCancel="onCancel"
      :getContainer="() => setCommissionRef"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="set-commission-wrap">
        <p class="first-p">The public commission is displayed to all publishers/creators when the product is active on the platform. Artemis Ads Fees are displayed to you and Artemis Ads only.</p>
        <div class="content">
          <div class="commission">
            <div class="commission-input">
              <span>Affiliate Commission</span>
              <a-input-number
                v-model:value="commission"
                :min="0"
                :max="100"
                :parser="(value) => value.replace('%', '')"
                ref="commissionMinRef"
                size="large"
              >
                <template #upIcon>
                  <svg class="icon" aria-hidden="true" font-size="12px">
                    <use xlink:href="#icon-jiantou-shang-cu"></use>
                  </svg>
                </template>
                <template #downIcon>
                  <svg class="icon" aria-hidden="true" font-size="12px">
                    <use xlink:href="#icon-jiantou-xia-cu"></use>
                  </svg>
                </template>
              </a-input-number>
              <span class="haifenhao">%</span>
              <span
                v-if="showDefalutText"
                class="defalut-text"
                @click="clickDefalutText"
                >Please enter...</span
              >
            </div>
            <span>+</span>
            <div class="fees">
              <span>ArtemisAds Fees</span>
              <artmisads-button disabled>{{ fees }}%</artmisads-button>
            </div>
            <span>=</span>
            <div class="total">
              <span>Total</span>
              <artmisads-button disabled>
                <span>{{
                  (Number(fees) + Number(commission)).toFixed(1)
                }}</span>
                <span>%</span>
              </artmisads-button>
            </div>
          </div>
          <p  class="second-p">You could receive an average of 10% of the sales back from Amazon as Brand Referral Bonus.</p>
          <p  class="third-p">The percentages displayed above will be applied to sales data that Artemis Ads receives from Amazon Attribution. Please note that sales reported by Amazon Attribution excludes Discounts (Instant Discount), Prime Day Deals, Prime Exclusive Deals, and Limited Time Deals, while DOES NOT exclude discounts from Promo Codes, Chippable Coupons, Subscribe & Save, or Lightning Deals.</p>
        </div>
      </div>
      <template #footer>
        <artmisads-button @click="saveCommission" type="primary"
          >Save Commission</artmisads-button
        >
      </template>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { message } from "ant-design-vue";
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import { productsApi } from "../../../server/index";

const showDefalutText = ref(true);
const commissionMinRef = ref(null);
const props = defineProps([
  "setSelectSingleProduct",
  "selectSingleProduct",
  "showSetCommission",
  "selectedRowKeys",
  "productList",
  "setShowSetCommission",
  "getProductList",
]);
const defaultCommission = ref({
  defaultCommissionRate: 1,
  platformFeeFactor: 1,
  platformMaxFeeRate: 1,
});
const commission = ref("");

const onCancel = () => {
  props.setShowSetCommission(false);
  props.setSelectSingleProduct("");
  commission.value = "";
};

const fees = computed(() => {
  let value = 0;
  if (commission.value) {
    value =
      (commission.value * defaultCommission.value.platformFeeFactor) / 100;
  } else {
    value =
      (defaultCommission.value.defaultCommissionRate *
        defaultCommission.value.platformFeeFactor) /
      100;
  }
  if (value >= defaultCommission.value.platformMaxFeeRate) {
    value = defaultCommission.value.platformMaxFeeRate;
  }
  return value.toFixed(1);
});

const setCommissionRef = ref(null);

watch(
  () => commission.value,
  (newValue) => {
    showDefalutText.value = newValue === null;
  }
);

const saveCommission = async () => {
  const params = {
    commissionRate: commission.value,
    ids: props.selectSingleProduct
      ? [props.selectSingleProduct]
      : props.selectedRowKeys,
    platformFeeRate: fees.value,
  };
  const res = await productsApi.setCommission(params);
  const { success, error } = res;
  if (success) {
    message.success("Success");
    props.getProductList();
    onCancel();
  }
};

const clickDefalutText = () => {
  showDefalutText.value = false;
  commissionMinRef.value.focus();
};

const getRate = async () => {
  const res = await productsApi.getCommissionConfig();
  const { success, data, error } = res;
  if (success) {
    defaultCommission.value = data;
  }
};

const setDefaultCommission = () => {
  const selectedRowKeysLength = props.selectedRowKeys.length;
  if (selectedRowKeysLength === 1) {
    commission.value = props.productList.find(
      (item) => item.id === props.selectedRowKeys[0]
    ).commission;
  } else if (selectedRowKeysLength > 1) {
    commission.value = defaultCommission.value.defaultCommissionRate;
  } else if (props.selectSingleProduct) {
    commission.value = props.productList.find(
      (item) => item.id === props.selectSingleProduct
    ).commission;
  }
};

watch(props, () => {
  setDefaultCommission();
});

onBeforeMount(() => {
  getRate();
});
</script>

<style lang="less" scoped>
  .set-commission {
    :deep(.ant-modal) {
      .ant-modal-body {
        padding: 0;
        border: none;
      }
    }
    .set-commission-content {
      p {
        font-family: Sora-Regular;
      }
      .first-p {
        padding: 24px 17px 24px 24px;
        color: var(--dark-3);
        font-weight: 400;
        line-height: 22px;
      }
      .third-p {
        margin-top: 28px;
        background: var(--big-bg-2);
        border-radius: 8px;
        padding: 8px 12px;
        font-size: var(--font-size-min);
        color: var(--dark-3);
        letter-spacing: 0.2px;
        line-height: 18px;
      }
      .second-p {
        font-size: var(--font-size-min);
        align-self: end;
        padding: 8px 12px;
        margin-top: 15px;
        width: 286px;
        background: var(--color-white);
        border: 1px solid var(--border-4);
        box-shadow: var(--section-box-shadow-2);
        border-radius: 8px;
        color: var(--dark-3);
        letter-spacing: 0.2px;
        line-height: 16px;
        font-style: italic;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: -10px;
          right : 115px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid var(--color-white);
          
        }
        &::before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: -11px;
          right : 115px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid var(--border-4);
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: 0 24px 24px 24px;
      .commission {
        display: flex;
        > span {
          align-self: center;
          margin: 20px 10px 0 10px;
          font-family: Sora-Regular;
          font-weight: 500;
          color: #0F0629;
        }
        .fees,.total,.commission-input {
          display: flex;
          flex-direction: column;
          > span {
            margin-bottom: 6px;
            font-weight: 500;
            color: var(--dark-2);
            line-height: 19px;
          }
        }
        .commission-input {
          position: relative;
          .defalut-text {
            position: absolute;
            font-family: Sora-Regular;
            color: var(--dark-4);
            line-height: 20px;
            left: 15px;
            top: 34px;
            margin: 0;
          }
          .haifenhao {
            position: absolute;
            font-weight: 500;
            font-size: var(--font-size-large);
            color: var(--dark-1);
            right: 30px;
            top: 34px;
            margin: 0;
          }
          :deep(.ant-input-number) {
            width: 200px;
            .ant-input-number-handler-wrap {
              opacity: 1;
            }
          }
        }
        .total {
          > button {
            width: 96px;
            display: flex;
            justify-content: space-between;
            > span {
              align-self: center;
            }
          }
        }
        .fees {
          > button {
            width: 144px;
          }
        }
        .fees,.total {
          > button {
            padding-left: 12px;
            text-align: left;
            border-radius: 8px;
            font-weight: 500;
            font-size: var(--font-size-large);
            color: var(--dark-1);
          }
        }
      }
    }
  }
      
.set-commission {
  :deep(.ant-modal) {
    .ant-modal-body {
      padding: 0;
      border: none;
    }
  }
  .set-commission-wrap {
    p {
      font-family: Sora-Regular;
    }
    .first-p {
      padding: 24px 17px 24px 24px;
      color: var(--dark-3);
      font-weight: 400;
      line-height: 22px;
    }
    .third-p {
      margin-top: 28px;
      background: var(--big-bg-2);
      border-radius: 8px;
      padding: 8px 12px;
      font-size: var(--font-size-min);
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 18px;
    }
    .second-p {
      font-size: var(--font-size-min);
      align-self: end;
      padding: 8px 12px;
      margin-top: 15px;
      width: 286px;
      background: var(--color-white);
      border: 1px solid var(--border-4);
      box-shadow: var(--section-box-shadow-2);
      border-radius: 8px;
      color: var(--dark-3);
      letter-spacing: 0.2px;
      line-height: 16px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -5px;
        right: 130px;
        width: 8px;
        height: 8px;
        border: 1px solid var(--border-1);
        border-color: var(--border-4) transparent transparent var(--border-4);
        background-color: #ffffff;
        transform: rotate(45deg);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;
    .commission {
      display: flex;
      > span {
        align-self: center;
        margin: 20px 10px 0 10px;
        font-family: Sora-Regular;
        font-weight: 500;
        color: #0f0629;
      }
      .fees,
      .total,
      .commission-input {
        display: flex;
        flex-direction: column;
        > span {
          margin-bottom: 6px;
          font-weight: 500;
          color: var(--dark-2);
          line-height: 19px;
        }
      }
      .commission-input {
        position: relative;
        .defalut-text {
          position: absolute;
          font-family: Sora-Regular;
          color: var(--dark-4);
          line-height: 20px;
          left: 15px;
          top: 34px;
          margin: 0;
        }
        .haifenhao {
          position: absolute;
          font-weight: 500;
          font-size: var(--font-size-large);
          color: var(--dark-1);
          right: 30px;
          top: 34px;
          margin: 0;
        }
        :deep(.ant-input-number) {
          width: 200px;
          .ant-input-number-handler-wrap {
            opacity: 1;
          }
        }
      }
      .total {
        > button {
          width: 96px;
          display: flex;
          justify-content: space-between;
          > span {
            align-self: center;
          }
        }
      }
      .fees {
        > button {
          width: 144px;
        }
      }
      .fees,
      .total {
        > button {
          padding-left: 12px;
          text-align: left;
          border-radius: 8px;
          font-weight: 500;
          font-size: var(--font-size-large);
          color: var(--dark-1);
        }
      }
    }
  }
}
</style>
